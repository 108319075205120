import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseTable from "../components/BaseTable";
import {
  getReqParamheader,
  deleteReqParamheader,
  postReqParamheader,
} from "../services/apiCall";
import { getItem, setItem } from "../utils/storage";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../components/PageSnipper";
import Pagination from "../components/Pagination";
import CustomButton from "../components/CustomButton";
import {CirclePlus, Frown, Search } from "lucide-react";

function ArticleHome() {
  const [articleList, setArticleList] = useState([]);
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalArticles, setTotalArticles] = useState(0); 
  const [currentPage, setCurrentPage] = useState(1); 
  const totalRowsPerPage = 7; // Number of rows per page

  const path = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setItem("org_id", orgid);
    setOrg(orgid);
    setUser(userid);
  }, []);

  useEffect(() => {
    if (orgId) {
      getArticleData(1); // Fetch articles on initial load and when page changes
    }
  }, [orgId]);

  const handleDelete = (id) => {
    let a = window.confirm("Are you sure you want to delete this article?");
    if (a) {
      setLoading(true);
      let url = `${API_URL_CONSTANT.baseUrl}/delete_articles/${id}/${orgId}/${userId}`;
      deleteReqParamheader(url)
        .then((res) => {
          if (res?.status) {
            // console.log('delete article response', res?.data)
            getArticleData(1);
            setLoading(false);
            toast.success(res?.data?.message);
          } else {
            console.log("response err", res?.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const handleRestore = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/restore_articles/${id}/${orgId}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('restore article response', res?.data)
          getArticleData(1);
          setLoading(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const handlePublice = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/publish_Article/${id}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('publice article response', res?.data)
          getArticleData(1);
          setLoading(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleEdit = (id) => {
    navigate(`${path}/${id}`);
  };

  const handleView = (id) => {
    navigate(`/view/${id}`);
  };

  const getArticleData = (page = 1) => {
    setLoading(true);
    let offset = (page - 1) * totalRowsPerPage + 1;
    let url = `${API_URL_CONSTANT.baseUrl}/All_article_details/${orgId}/${userId}?Limit=${totalRowsPerPage}&Offset=${offset}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          setArticleList(res?.data?.data);
          setTotalArticles(res?.data?.count); 
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        }, 1000);
      })
  };

  const totalPages = Math.ceil(totalArticles / totalRowsPerPage); 

  const handlePageChange = (page) => {
    setCurrentPage(page);
    searchTerm !== "" ? handleSearch(page) : getArticleData(page);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (orgId && searchTerm === "") {
        getArticleData(1); 
      }
    }, 300); 

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearch = (page = 1) => {
    setLoading(true);
    let offset = (page - 1) * totalRowsPerPage + 1;
    let url = `${API_URL_CONSTANT.baseUrl}/searchArticleByTitle/${orgId}/${userId}?Limit=${totalRowsPerPage}&Offset=${offset}&q=${encodeURIComponent(searchTerm)}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          setArticleList(res?.data?.data);
          setTotalArticles(res?.data?.count);
          setLoading(false);
        } else {
          console.log('response err', res?.message);
        }
      })
      .catch((err) => {
        console.log('err', err);
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const createArticle = () => {
    navigate(`${path}/create`);
  }
  return (
    <>
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex justify-between items-center mb-3">
            <p className="text-lg lg:text-xl font-medium"> Articles ({totalArticles}) </p>
            <div className="flex gap-6 items-center">
              <div className="w-[30vw] relative">
                <input type="text" placeholder="Search Articles by Title..." className="w-full px-2 py-1 md:px-3 md:py-1.5 text-sm md:text-base placeholder-gray-500 pe-8 md:pe-24 rounded-sm outline-1 focus:outline-blue-500 active:outline-blue-500 transition-all " value={searchTerm} onChange={ e => setSearchTerm(e.target.value)}/>
                <CustomButton icon={<Search size={20} color="#fff" />} content={"Search"} bgColor={"bg-blue-600 "} clickHandler={() => handleSearch(1)} additionalStyles={"text-white hover:bg-blue-700 w-fit absolute top-0 right-0"} disabled={searchTerm === ""}/>
              </div>
              <CustomButton icon={<CirclePlus size={20}/>} bgColor={"bg-blue-600"} content={"Create an Article"} clickHandler={createArticle} additionalStyles={"text-white hover:bg-blue-700"}/>
            </div>
          </div>

          {articleList?.length > 0 ? (
            <div>
              <BaseTable
                header={["Title", "Document Status", "Last Accessed", "Actions"]}
                data={articleList}
                remove={handleDelete}
                edit={handleEdit}
                restore={handleRestore}
                publice={handlePublice}
                view={handleView}
                type="article"
              />
            </div>
          ) : (
            <div className="w-full h-[60vh] flex justify-center flex-col items-center gap-8">
              <Frown size={48} color="#6a6868" strokeWidth={1.25} />
              <p className="text-lg text-gray-600 font-medium">No Article found</p>
            </div>
          )}

          {
            totalArticles > totalRowsPerPage && 
            <Pagination 
              total={totalPages}
              current={currentPage}
              onPageChange={page => handlePageChange(page)}
            />
          }
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default ArticleHome;
