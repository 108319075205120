import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";

function PageSnipper({ loading }) {
    return (
        <>
            <div className='h-[60vh] flex justify-center items-center'>
                <ClipLoader
                    color='gray'
                    loading={loading}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </>

    )
}

export default PageSnipper
