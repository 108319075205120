import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseTable from "../components/BaseTable";
import {
  getReqParamheader,
  deleteReqParamheader,
  postReqParamheader,
} from "../services/apiCall";
import { getItem, setItem } from "../utils/storage";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { ToastContainer, toast } from "react-toastify";
import PageSnipper from "../components/PageSnipper";
import CustomButton from "../components/CustomButton";
import {CirclePlus, Frown } from "lucide-react";

import Modal from "react-modal";

function WidgetHome() {
  const [widgetList, setWidgetList] = useState([]);
  const [orgId, setOrg] = useState("");
  const [userId, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [widgetCode, setWidgetCode] = useState("");

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setItem("org_id", orgid);
    setOrg(orgid);
    setUser(userid);
  }, []);

  function openModal(widgetid) {
    setShowModal(true);
    setWidgetCode(generateWidgetCode(widgetid));
  }

  function closeModal() {
    setShowModal(false);
  }

  useEffect(() => {
    if (orgId) {
      getArticleData(orgId);
    }
  }, [orgId]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#efefef",
    },
  };

  const handleDelete = (id) => {
    let a = window.confirm("Are you sure you want to delete this article?");
    if (a) {
      setLoading(true);
      let url = `${API_URL_CONSTANT.baseUrl}/delete_widgets/${id}/${orgId}/${userId}`;
      deleteReqParamheader(url)
        .then((res) => {
          if (res?.status) {
            // console.log('delete article response', res?.data)
            getArticleData(orgId);
            setLoading(false);
            toast.success(res?.data?.message);
          } else {
            console.log("response err", res?.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const handleRestore = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/restore_widgets/${id}/${orgId}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('restore article response', res?.data)
          getArticleData(orgId);
          setLoading(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const handlePublice = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/publish_Widgets/${id}/${userId}`;
    postReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('publice article response', res?.data)
          getArticleData(orgId);
          setLoading(false);
          toast.success(res?.data?.message);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleEdit = (id) => {
    navigate(`${path}/${id}`);
  };

  const handleView = (id) => {
    //navigate(`/view/${id}`)
    openModal(id);
  };

  const getArticleData = (id) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/All_widget_details/${id}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          console.log("get widget list response", res?.data);
          setWidgetList(res?.data?.data);
          setLoading(false);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setTimeout(() => {
          toast.error(err?.response?.data?.message);
        }, 1000);
      });
  };

  const generateWidgetCode = (widgetid) => {
    let widgetcode = `<div id='${widgetid}-container'><script>var script=document.createElement('script');script.setAttribute('src','https://smorgborg.org/widget.js?'+Date.now());script.onload=function(){var widget=window.SmorgborgWidget('${widgetid}','https://smorgborg.org');widget.waitForElementToDisplay();};document.body.appendChild(script);</script></div>`;
    return widgetcode;
  };

  const copyCode = () => {
    navigator.clipboard.writeText(widgetCode);
    toast.success("Code has been copied to your clipboard.");
  };

  const handleCreateWidget = () => {
    navigate(`${path}/create`)
  };

  return (
    <>
      {" "}
      {loading ? (
        <PageSnipper loading={loading} />
      ) : (
        <div className="px-6">
          <div className="flex justify-between mb-3">
            <p className="text-2xl"> Widgets ({widgetList.length}) </p>
            <CustomButton icon={<CirclePlus size={20}/>} bgColor={"bg-blue-600"} content={"Create a Widget"} clickHandler={handleCreateWidget} additionalStyles={"text-white hover:bg-blue-700"}/>
          </div>
          {widgetList?.length > 0 ? (
            <div>
              <BaseTable
                header={["Name", "Document Status", "Last Accessed", "Actions"]}
                data={widgetList}
                remove={handleDelete}
                edit={handleEdit}
                restore={handleRestore}
                publice={handlePublice}
                view={handleView}
                type="widget"
              />
            </div>
          ) : (
            <div className="w-full h-[60vh] flex justify-center flex-col items-center gap-8">
              <Frown size={48} color="#6a6868" strokeWidth={1.25} />
              <p className="text-lg text-gray-600 font-medium">No Widget found</p>
            </div>
          )}
          <ToastContainer />

          {showModal && (
            <Modal
              isOpen={showModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Widget Code"
            >
              <div>
                Here is the code you would need to copy and paste on your
                website
              </div>
              <div class="flex justify-center py-2">
                <textarea
                  id="widgetcode"
                  style={{ height: "25vh", width: "30vw" }}
                >
                  {widgetCode}
                </textarea>
              </div>
              <div class="flex justify-center">
                <div className="flex justify-center bg-black text-white py-2 px-2 mx-2 w-[35%]">
                  <button onClick={copyCode}>Copy Code</button>
                </div>
                <div className="flex justify-center bg-black text-white py-2 px-2 mx-2 w-[35%]">
                  <button onClick={closeModal}>Close</button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
}

export default WidgetHome;
