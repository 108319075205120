import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  faEnvelope,
  faEnvelopeOpen,
  faCircleExclamation,
  faBellSlash,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { List } from "antd";

import PageSnipper from "../../components/PageSnipper";
import { getReqParamheader } from "../../services/apiCall";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import StatisticsWidget from "../../components/StatWidget";
import BaseBackButton from "../../components/BaseBackButton";

export default function EmailStats() {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const { EmailId } = useParams();

  useEffect(() => {
    getEmailStats(EmailId);
  }, [EmailId]);

  const getEmailStats = (emailId) => {
    setLoading(true);
    let url = `${API_URL_CONSTANT.baseUrl}/viewstats/${emailId}`;
    getReqParamheader(url)
      .then((res) => {
        setLoading(false);
        setStats(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return loading ? (
    <PageSnipper loading={loading} />
  ) : (
    <div className="px-6">
      <p className="mb-5">
        <BaseBackButton />
        <span className="text-2xl ml-2">Email Stats: {stats.Subject}</span>
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-8">
          <StatisticsWidget
            label="Delivered"
            icon={faEnvelope}
            count={stats.Delivered_Count}
          />
          <StatisticsWidget
            label="Opened"
            icon={faEnvelopeOpen}
            count={stats.Opened_Count}
          />
          <StatisticsWidget
            label="Clicked"
            icon={faEnvelopeOpen}
            count={stats.Clicked_Count}
          />

          <StatisticsWidget
            label="Deferred"
            icon={faClock}
            count={stats.Deferred_Count}
          />
          <StatisticsWidget
            label="Bounced"
            icon={faCircleExclamation}
            count={stats.Bounced_Count}
          />
          <StatisticsWidget
            label="Unsubscribe"
            icon={faBellSlash}
            count={stats.Unsubscribe_Count}
          />
        {stats?.Bounced_Emails?.length > 0 ? (
            <List
              size="small"
              header={
                <div className="text-lg bold">
                  Bounced Email ids ({stats.Bounced_Emails.length})
                </div>
              }
              bordered
              dataSource={stats.Bounced_Emails}
              renderItem={(item) => (
                <List.Item className="bg-white">{item}</List.Item>
              )}
            />
        ) : null}
        {stats?.Unsubscribe_Emails?.length > 0 ? (
            <List
              size="small"
              header={
                <div className="text-lg bold">
                  Unsubscribed ids({stats.Unsubscribe_Emails?.length})
                </div>
              }
              bordered
              dataSource={stats.Unsubscribe_Emails}
              renderItem={(item) => (
                <List.Item className="bg-white">{item}</List.Item>
              )}
            />
        ) : null}
      </div>
    </div>
  );
}
