import React from "react";

export default function Modal({ setOpenModal, title, children, type, item,remove }) {
    return (
        <>
            {
                type === 'contact' ?

                    <>
                        <div className="fixed inset-0 z-10 overflow-y-auto my-10 w-full">
                            <div
                                className="fixed inset-0 w-full h-full bg-black opacity-40"
                                onClick={() => setOpenModal(false)}
                            ></div>
                            <div className="flex items-center min-h-screen">
                                <div className="relative w-[50%]  py-3 mx-auto bg-white rounded-md shadow-lg">
                                    <div className="flex justify-between border-b pb-3">
                                        <div className="text-bold pl-6">
                                            <div className='flex justify-start gap-4'>
                                                <div className="border border-gray-400  w-12 h-12 bg-gray-100 rounded-full flex justify-center items-center">
                                                    <div> { item?.FirstName && item?.FirstName?.split('')[0].toUpperCase() || 'M'}</div>
                                                </div>
                                                <div className=''>
                                                    <div className='flex gap-1'>
                                                        <p className='text-md text-gray-600'> {item?.FirstName}</p>
                                                        <p className='text-md text-gray-600'> {item?.LastName}</p>
                                                    </div>
                                                    <p className='text-sm text-gray-600'> {item?.Email}</p>
                                                    <span className='transition-all text-sm text-red-400 hover:text-red-700 cursor-pointer'  onClick={() => remove(item?._id)}>Delete Profile </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => setOpenModal(false)} className='cursor-pointer pr-6'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="px-6 py-2">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-40"
                            onClick={() => setOpenModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen">
                            <div className="relative w-full max-w-lg py-3 mx-auto bg-white rounded-md shadow-lg">
                                <div className="flex justify-between border-b pb-3">
                                    <div className="text-bold pl-6">
                                        {title}
                                    </div>
                                    <div onClick={() => setOpenModal(false)} className='cursor-pointer pr-6'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="px-6 py-2">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>
    );
}
