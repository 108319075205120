import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { getItem } from "../../utils/storage";
import {
  getReqParamheader,
  postReqParamheaderFile,
} from "../../services/apiCall";
import { Parser } from 'html-to-react';

function DefaultArtical({ data, id, hs, hf, ts, tf, type, save, read, currentid }) {

  const [articleData, setArticleData] = useState(data || {});
  const htmlToReactParser = new Parser();
  const containerRef = useRef(null);
  const path = useLocation().pathname;

  useEffect(() => {
      if (id) {
          getArticleData(id);
      }
  }, [id]);

  const getArticleData = async (id) => {
      const apiurl = `${API_URL_CONSTANT.baseUrl}/article_details/${id}`;
      try {
          const res = await getReqParamheader(apiurl);
          if (res?.status) {
              setArticleData(res?.data?.data?.draft);
          } else {
              console.error("Response error", res?.message);
          }
      } catch (err) {
          console.error("Error fetching article data", err);
      }
  };

  useEffect(() => {
      if (containerRef.current) {
          let img = new Image();
          img.src = articleData?.Featured_Photo;
          img.onload = () => {
              let aratio = (img.height / img.width).toFixed(2);
              let w = img.width > 600 ? 600 : img.width;
              let h = img.width > 600 ? (600 * aratio) : img.height;
              save(currentid, w.toString(), h.toString());
          };
      }
  }, [articleData, save, currentid]);

  useEffect(() => {
      const userid = getItem("user_id");
      const orgid = path.split("/")[2];
      setArticleData(prevData => ({ ...prevData, org: orgid, user: userid }));
  }, [path]);

  const newDateOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
  };

  const desL = htmlToReactParser.parse(articleData?.Content?.slice(0, 300));
  const desM = htmlToReactParser.parse(articleData?.Content);
  const title = htmlToReactParser.parse(articleData?.Title);
  const ed = htmlToReactParser.parse(articleData?.Event_Description);
  const ed_excerpt = htmlToReactParser.parse(articleData?.Event_Description?.slice(0, 300));
  const url = `https://smorgborg.org/view/${id}`;

    return (
        <>
            {
                type && type === 'event' ?
                <table border="0" cellSpacing="0" width="100%">
                <tbody>
                <tr>
                    <td width="600" align="center">
                    <div style={{ textAlign: 'start' }}>
                        <p style={{ fontSize: `${hs}`, fontFamily: `${hf}` }}><a target="_blank" href={data?.Event_Website}>{data?.Event_Name}</a></p>
                        {
                            data?.Featured_Photo &&
                            <div style={{ width: '100%', textAlign: '-webkit-center' }} >
                                <a target="_blank" href={data?.Event_Website}><img ref={containerRef} src={data?.Featured_Photo} style={{ maxHeight: "600px", maxWidth: "600px" }} alt="feature photo" /></a>
                            </div>

                        }
                        <p style={{ fontSize: `${ts}`, fontFamily: `${tf}` }}> {data?.Event_Start_Date.toLocaleString("en-US", newDateOptions)}</p>
                        {/* <p style={{ fontSize: `${ts}`, fontFamily: `${tf}` }}>End Event : {data?.Event_End_Time}</p> */}
                        
                        <p width="600" style={{fontSize: `${ts}`, fontFamily: `${tf}`, width: "600px", wordWrap: "break-word"}}>
                            {
                                read ?
                                    ed_excerpt
                                    :
                                    ed  
                            }
                            {
                                read ?
                                    <a href={data?.Event_Website} target='_blank' >...Read more</a>
                                    :
                                    null
                            }
                        </p>
                        {/* <a   target='_blank'>
                            <button style={{ padding: '8px 16px', background:'blue', fontSize: '16px', color: 'white', border: 'none', borderRadius: '2px' }}>
                                View Event
                            </button>
                        </a> */}

                    </div>
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    :
                    <table border="0" cellSpacing="0" width="100%">
                    <tbody>
                    <tr>
                        <td width="600" align="center">
                    <div style={{ textAlign: 'start' }}>
                    <a target="_blank" href={url}><p style={{ fontSize: `${hs}`, fontFamily: `${hf}` }}>
                            {title}
                        </p></a>
                        {
                            data?.Featured_Photo && <div style={{ width: '100%', textAlign: '-webkit-center' }} >
                                <a target="_blank" href={url}><img ref={containerRef} src={data?.Featured_Photo} style={{ maxHeight: "600px", maxWidth: "600px" }} alt="feature photo" /></a>
                            </div>
                        }

                        <p style={{ fontSize: `${ts}`, fontFamily: `${tf}`, width: "600px" }}>
                            {
                                read ?
                                    desL
                                    :
                                    desM
                            }
                            {
                                read ?
                                    <a href={url} target='_blank' >...Read more</a>
                                    :
                                    null
                            }

                        </p>

                    </div>
                    </td>
                    </tr>
                    </tbody>
                    </table>
            }

        </>

    )
}

export default DefaultArtical
